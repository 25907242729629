.md blockquote {
  background-color: rgba(148, 148, 149, 0.08) ;
  padding: 1px 1rem;
  border-left: 4px solid var(--pm);
  font-style: italic;
}

.md table {
  border-collapse: collapse;
  border-spacing: 0;
  display: block;
  max-width: 100%;
  width: 100%;
  margin: 1em 0;
  overflow: auto;
}

.md table thead {
  border-top: 2px solid var(--ft);
  border-bottom: 1px solid var(--ft);
}

.md table tbody {
  border-bottom: 2px solid var(--ft);
}

.md table th,
.md table td {
  padding: 10px 20px;
}

.md img {
  max-width: 100%;
}

.md .katex {
  overflow: auto hidden;
}

.md ul input[type="checkbox"] {
  margin-right: 3px;
}

.md .ul-checkbox {
  list-style: none;
  padding-inline-start: 20px;
}

.md li {
  margin-bottom: 1rem;
  line-height: 1.5;
}

.md ol,
.md ul,
.md img,
.md blockquote,
.md .highlight {
  margin: .75rem 0;
}

.md blockquote p {
  margin: .5rem 0;
  line-height: 1.5;
  font-size: .9rem;
}

.md hr {
  margin: 1.5rem 0;
}

.md .footnotes {
  word-break: break-all;
  font-size: .9rem;
}

.md p sup {
  margin-left: 4px;
}

.md a {
  text-decoration: underline;
}

.md p a {
  font-style: italic;
}
