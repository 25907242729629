@import "atom.scss";
@import "markdown.scss";
@import "syntax.scss";
@import "toc.scss";

:root {
  --pm: #0fa0ce;
  --bd: #e1e1e1;
  --bg: #fff;
  --ft: #222;
  --tag: #333;

  --w-mobile: 640px;

  --global-font-family: system-ui, -apple-system, BlinkMacSystemFont,
  "Segoe UI",
  "Ubuntu",
  "Roboto", "Noto Sans", "Droid Sans",
  sans-serif;

  --global-serif-font: Georgia, Cambria, "Times New Roman", Times, serif;

  --global-monospace-font:  ui-monospace, "Cascadia Mono", "Segoe UI Mono" "Ubuntu Mono", "Roboto Mono", Menlo, Monaco, Consolas, monospace;
}

html {
  scroll-behavior: smooth;
}

:target:before {
  content: "";
  display: block;
  height: 50px;
  margin: -50px 0 0;
}

html.dark {
  --bg: #181818;
  --ft: silver;
  --bd: #555;
  --tag: #999;

  --ovo-ft: var(--ft);
  --ovo-bdr: var(--bd);
  --ovo-bg: var(--bg);
  --ovo-bg-hvr: #555;
  --ovo-tag: #30363d;
}

body {
  max-width: var(--w-mobile);
  margin: 3rem auto 0;
  font-family: var(--global-font-family);
  font-size: 16px;
  font-weight: 400;
  color: var(--ft);
  background-color: var(--bg);
  transition-property: background-color, border-color, color;
  transition-duration: 0.5s;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
  line-height: 1.5;
}

p {
  line-height: 1.8;
}

hr {
  border-width: 0;
  border-top: 1px solid var(--bd);
}

pre,
code {
  font-family: var(--global-monospace-font);
  font-size: 13px;
}

.minima-tag,
p code {
  background-color: var(--bd);
  padding: 0.2em 0.4em;
  border-radius: 1px;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: var(--pm);
}

main {
  animation: showup 0.7s;
}

p a {
  font-style: italic;
  text-decoration: underline;
}

footer a {
  font-style: unset;
  text-decoration: none;
}

header nav {
  color: var(--tag);
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li.active {
  font-weight: 700;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.7);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(128, 128, 128, 0.2);
}

::-webkit-scrollbar-thumb:active {
  background-color: rgb(128, 128, 128);
}

h1::-webkit-scrollbar,
nav::-webkit-scrollbar {
  display: none;
  width: 0;
}

@keyframes showup {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.home-intro {
  line-height: 1.65;
}

.callout {
    background-color: rgba(148, 148, 149, 0.08) ;
    padding:  1rem;
    border-left: 4px solid var(--pm);
    font-style: italic;
    line-height: 1.5;
    font-size: 1.25em;
}

.product-box {
  background-color: rgba(148, 148, 149, 0.08) ;
  padding:  1.5rem;
  border-radius: 4px;
  border: 1px solid var(--ft);
  margin: .75rem 0;
  line-height: 1.5;
}

.product-box--item {
  padding:  .5rem 0;
  line-height: 1.5;
}

.product-box--item a {
  text-decoration: none;
}

.product-box--item a::after {
  content: " \00BB"
}


li {
    margin-bottom: 1rem;
}

.flexed {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 690px)  {
  .flexed {
    margin-top: 1.25rem;
    flex-direction: column;
  }
  .flexed > .separator {
    display: none;
  }
  .flexed > * {
    padding-bottom: 1.25rem;
  }
}
